exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-digital-name-card-js": () => import("./../../../src/pages/digital-name-card.js" /* webpackChunkName: "component---src-pages-digital-name-card-js" */),
  "component---src-pages-dynamic-qr-code-js": () => import("./../../../src/pages/dynamic-qr-code.js" /* webpackChunkName: "component---src-pages-dynamic-qr-code-js" */),
  "component---src-pages-en-404-js": () => import("./../../../src/pages/en/404.js" /* webpackChunkName: "component---src-pages-en-404-js" */),
  "component---src-pages-en-cookie-policy-js": () => import("./../../../src/pages/en/cookie-policy.js" /* webpackChunkName: "component---src-pages-en-cookie-policy-js" */),
  "component---src-pages-en-dynamic-qr-code-js": () => import("./../../../src/pages/en/dynamic-qr-code.js" /* webpackChunkName: "component---src-pages-en-dynamic-qr-code-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-pricing-js": () => import("./../../../src/pages/en/pricing.js" /* webpackChunkName: "component---src-pages-en-pricing-js" */),
  "component---src-pages-en-privacy-js": () => import("./../../../src/pages/en/privacy.js" /* webpackChunkName: "component---src-pages-en-privacy-js" */),
  "component---src-pages-en-privacy-thirdparty-js": () => import("./../../../src/pages/en/privacy-thirdparty.js" /* webpackChunkName: "component---src-pages-en-privacy-thirdparty-js" */),
  "component---src-pages-en-qr-code-js": () => import("./../../../src/pages/en/qr-code.js" /* webpackChunkName: "component---src-pages-en-qr-code-js" */),
  "component---src-pages-en-short-url-js": () => import("./../../../src/pages/en/short-url.js" /* webpackChunkName: "component---src-pages-en-short-url-js" */),
  "component---src-pages-en-terms-of-service-js": () => import("./../../../src/pages/en/terms-of-service.js" /* webpackChunkName: "component---src-pages-en-terms-of-service-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pet-tag-js": () => import("./../../../src/pages/pet-tag.js" /* webpackChunkName: "component---src-pages-pet-tag-js" */),
  "component---src-pages-preview-en-permalink-js": () => import("./../../../src/pages/preview/en/[...Permalink].js" /* webpackChunkName: "component---src-pages-preview-en-permalink-js" */),
  "component---src-pages-preview-permalink-js": () => import("./../../../src/pages/preview/[...Permalink].js" /* webpackChunkName: "component---src-pages-preview-permalink-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-privacy-thirdparty-js": () => import("./../../../src/pages/privacy-thirdparty.js" /* webpackChunkName: "component---src-pages-privacy-thirdparty-js" */),
  "component---src-pages-qr-code-js": () => import("./../../../src/pages/qr-code.js" /* webpackChunkName: "component---src-pages-qr-code-js" */),
  "component---src-pages-qrcode-customer-feedback-js": () => import("./../../../src/pages/qrcode-customer-feedback.js" /* webpackChunkName: "component---src-pages-qrcode-customer-feedback-js" */),
  "component---src-pages-short-url-js": () => import("./../../../src/pages/short-url.js" /* webpackChunkName: "component---src-pages-short-url-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-templates-blogs-details-js": () => import("./../../../src/templates/blogs/details.js" /* webpackChunkName: "component---src-templates-blogs-details-js" */),
  "component---src-templates-blogs-en-index-js": () => import("./../../../src/templates/blogs/en/index.js" /* webpackChunkName: "component---src-templates-blogs-en-index-js" */),
  "component---src-templates-blogs-index-js": () => import("./../../../src/templates/blogs/index.js" /* webpackChunkName: "component---src-templates-blogs-index-js" */)
}

